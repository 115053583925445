const state = {
  consent: localStorage.getItem('analytics_consent'),
}

const getters = {
  consent: (state) => state.consent,
  consentGranted: (state) => state.consent === true,
  consentDenied: (state) => state.consent === false,
  consentUnknown: (state) => state.consent !== true && state.consent !== false,
}

const actions = {
  grantConsent ({ commit }) {
    commit('CONSENT', true)
  },

  denyConsent ({ commit }) {
    commit('CONSENT', false)
  },

  unsetConsent ({ commit }) {
    commit('CONSENT', undefined)
  },
}

const mutations = {
  CONSENT (state, payload) {
    state.consent = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
