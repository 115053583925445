<template>
  <div
    v-if="displayConsentPrompt"
    class="consent-bar"
  >
    <div class="columns">
      <div class="column">
        <p>
          <strong>We would like to analyse the traffic to our website</strong> and we need to ask if you're OK with this. We won't start
          doing it until you allow us to.
        </p>
        <p>
          This data is processed and stored on our own server, per our <safe-link to="/privacy">Privacy Policy</safe-link>;
          you can change your consent at any time from there.
          <transition
            name="consent-text"
            mode="out-in"
            enter-active-class="animate__animated animate__fadeIn animate__fast"
          >
            <span
              v-if="consentGranted"
              key="granted"
              class="consent-text granted"
            >
              Currently, you allow this.
            </span>
            <span
              v-if="consentDenied"
              key="denied"
              class="consent-text denied"
            >
              Currently, you do not allow this.
            </span>
          </transition>
        </p>
      </div>
      <div class="column is-narrow-tablet">
        <b-button
          type="is-success"
          outlined
          expanded
          @click="grantConsent"
        >
          Allow
        </b-button>
        <b-button
          type="is-danger"
          outlined
          expanded
          @click="denyConsent"
        >
          Deny
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import SafeLink from '@/components/helpers/safe-link'

  export default {
    name: 'analytics-consent',

    components: {
      SafeLink,
    },

    computed: {
      consentGranted () {
        return this.$store.getters['analytics/consentGranted']
      },

      consentDenied () {
        return this.$store.getters['analytics/consentDenied']
      },

      displayConsentPrompt () {
        // Always display on the privacy page
        if (this.$route.path === '/privacy') return true

        return this.$store.getters['analytics/consentUnknown']
      },
    },

    methods: {
      grantConsent () {
        this.$store.dispatch('analytics/grantConsent')
        this.$matomo && this.$matomo.rememberConsentGiven()
        window.location.reload()
      },

      denyConsent () {
        this.$store.dispatch('analytics/denyConsent')
        this.$matomo && this.$matomo.forgetConsentGiven()
        window.location.reload()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @use 'sass:color';

  @import '@/assets/bulma-variables.scss';

  .consent-bar {
    margin: 1rem 0 0 0;
    border: 12px double $primary;
    box-shadow: $body-background-color 0 -5px 20px 20px;
    background-color: color.scale($primary, $lightness: 95%); // stylelint-disable-line unit-allowed-list -- % is OK for manipulations
    min-height: 8rem;

    @include on-phone {
      font-size: 0.9rem;
    }

    .columns {
      padding: 2rem;

      .column {
        padding: 0;

        &:first-child {
          padding-right: 2rem;
        }
      }
    }
  }

  button {
    &.is-success {
      font-weight: $weight-bold;
    }

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    @include on-phone {
      &:first-child {
        margin-top: 1rem;
      }
    }
  }

  .consent-text {
    display: inline-block;
    font-weight: $weight-bold;

    &.granted {
      color: $success;
    }

    &.denied {
      color: $danger;
    }
  }
</style>
