import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import AuthService from '@/services/auth-service'

import NotFound from '@/views/not-found'
import FrontPage from '@/views/front-page'
import PrivacyPolicy from '@/views/privacy-policy'
import BeerFestivals from '@/views/beer-festivals'
import BeerFestival from '@/views/beer-festival'
import CalendarFeeds from '@/views/calendar-feeds'
import CalendarFeed from '@/views/calendar-feed'
import Events from '@/views/events'
import Event from '@/views/event'
import Issues from '@/views/issues'
import Issue from '@/views/issue'
import Pages from '@/views/pages'
import Page from '@/views/page'
import Publications from '@/views/publications'
import Publication from '@/views/publication'
import Settings from '@/views/settings'
import ResetPassword from '@/views/users/reset-password'
import ConfirmEmail from '@/views/users/confirm-email'
import UnlockAccount from '@/views/users/unlock-account'

import DynamicRedirect from '@/components/helpers/dynamic-redirect'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    component: FrontPage,
  },
  {
    path: '/privacy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
    },
  },

  // Beer festivals
  {
    path: '/beer-festivals/new',
    component: BeerFestival,
    props: {
      mode: 'create',
    },
    meta: {
      title: 'New Beer Festival',
    },
  },
  {
    path: '/beer-festivals/:id/edit',
    component: BeerFestival,
    props: (route) => ({
      mode: 'edit',
      id: route.params.id,
    }),
    meta: {
      title: 'Beer Festival', // will be changed on page load
    },
  },
  {
    path: '/beer-festivals/:id',
    component: BeerFestival,
    props: (route) => ({
      mode: 'show',
      id: route.params.id,
    }),
    meta: {
      title: 'Beer Festival', // will be changed on page load
    },
  },
  {
    path: '/beer-festivals',
    name: 'beer-festivals',
    component: BeerFestivals,
    meta: {
      title: 'Beer Festivals',
    },
  },

  // Calendar feeds
  {
    path: '/calendar-feeds/new',
    component: CalendarFeed,
    props: {
      mode: 'create',
    },
    meta: {
      title: 'New Calendar Feed',
    },
  },
  {
    path: '/calendar-feeds/:id/edit',
    component: CalendarFeed,
    props: (route) => ({
      mode: 'edit',
      id: route.params.id,
    }),
    meta: {
      title: 'Calendar Feed', // will be changed on page load
    },
  },
  {
    path: '/calendar-feeds/:id',
    component: CalendarFeed,
    props: (route) => ({
      mode: 'show',
      id: route.params.id,
    }),
    meta: {
      title: 'Calendar Feed', // will be changed on page load
    },
  },
  {
    path: '/calendar-feeds',
    name: 'calendar-feeds',
    component: CalendarFeeds,
    meta: {
      title: 'Calendar Feeds',
    },
  },

  // Events
  {
    path: '/events/:id',
    component: Event,
    props: (route) => ({
      id: route.params.id,
    }),
    meta: {
      title: 'Branch Diary', // will be changed on page load
    },
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      title: 'Branch Diary',
    },
  },

  // Pages
  {
    path: '/pages/new',
    component: Page,
    props: {
      mode: 'create',
    },
    meta: {
      title: 'New Page',
    },
  },
  {
    path: '/pages/:id/edit',
    component: Page,
    props: (route) => ({
      mode: 'edit',
      id: route.params.id,
    }),
    meta: {
      title: 'Page', // will be changed on page load
    },
  },
  {
    path: '/pages/:id',
    component: Page,
    props: (route) => ({
      mode: 'show',
      id: route.params.id,
    }),
    meta: {
      title: 'Page', // will be changed on page load
    },
  },
  {
    path: '/pages',
    name: 'pages',
    component: Pages,
    meta: {
      title: 'Pages',
    },
  },

  // Publications and issues
  {
    path: '/publications/new',
    component: Publication,
    props: {
      mode: 'create',
    },
    meta: {
      title: 'New Publication',
    },
  },
  {
    path: '/publications/:id/edit',
    component: Publication,
    props: (route) => ({
      mode: 'edit',
      id: route.params.id,
    }),
    meta: {
      title: 'Publication', // will be changed on page load
    },
  },
  { // a publication's view is just a list of its issues
    path: '/publications/:id',
    redirect: '/publications/:id/issues',
  },
  {
    path: '/publications',
    name: 'publications',
    component: Publications,
    meta: {
      title: 'Publications',
    },
  },
  {
    path: '/publications/:publicationId/issues',
    name: 'issues',
    component: Issues,
    props: (route) => ({
      publicationId: route.params.publicationId,
    }),
    meta: {
      title: 'Issues', // will be changed on page load
    },
  },
  {
    path: '/publications/:publicationId/issues/new',
    component: Issue,
    props: (route) => ({
      mode: 'create',
      publicationId: route.params.publicationId,
    }),
    meta: {
      title: 'New Issue',
    },
  },
  {
    path: '/publications/:publicationId/issues/:id/edit',
    component: Issue,
    props: (route) => ({
      mode: 'edit',
      id: route.params.id,
      publicationId: route.params.publicationId,
    }),
    meta: {
      title: 'Issue', // will be changed on page load
    },
  },
  {
    path: '/publications/:publicationId/issues/:id',
    component: Issue,
    props: (route) => ({
      mode: 'show',
      id: route.params.id,
      publicationId: route.params.publicationId,
    }),
    meta: {
      title: 'Issue', // should never be seen as this redirects to the PDF
    },
  },

  // Settings
  {
    path: '/settings',
    component: Settings,
    meta: {
      title: 'Site Settings',
    },
  },

  // Users
  {
    path: '/users/reset-password/:token',
    component: ResetPassword,
    props: (route) => ({
      token: route.params.token,
    }),
    meta: {
      title: 'Reset Password',
    },
  },
  {
    path: '/users/confirm/:token',
    component: ConfirmEmail,
    props: (route) => ({
      token: route.params.token,
    }),
    meta: {
      title: 'Confirm Email',
    },
  },
  {
    path: '/users/unlock/:token',
    component: UnlockAccount,
    props: (route) => ({
      token: route.params.token,
    }),
    meta: {
      title: 'Unlock Account',
    },
  },

  // Redirects for routes from old site
  {
    path: '/legal',
    redirect: '/privacy',
  },
  {
    path: '/about-the-branch',
    component: DynamicRedirect,
    props: {
      from: '/about-the-branch',
    },
  },
  {
    path: '/contact-us',
    component: DynamicRedirect,
    props: {
      from: '/contact-us',
    },
  },
  {
    path: '/locale',
    component: DynamicRedirect,
    props: {
      from: '/locale',
    },
  },
  {
    path: '/trading-standards',
    component: DynamicRedirect,
    props: {
      from: '/trading-standards',
    },
  },
  {
    path: '/yapton-beerex-*',
    component: DynamicRedirect,
    props: {
      from: '/yapton-beerex',
    },
  },

  // fallback
  {
    path: '*',
    component: NotFound,
    meta: {
      title: 'Page Not Found',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return {
        x: 0,
        y: 0,
      }
    }
  },
})

const siteName = 'Western Sussex CAMRA'

export const setTitle = (title) => {
  Vue.nextTick()
    .then(() => {
      if (title) {
        document.title = `${ title } | ${ siteName }`
      } else {
        document.title = siteName
      }
    })
}

const setAnalyticsConsent = () => {
  const consentGranted = !!Vue.$cookies.get('mtm_consent')
  const consentDenied = !!Vue.$cookies.get('mtm_consent_removed')

  // On user's first visit, neither cookie should be set. Matomo should not set both at once; if they are, treat as unknown and ask again
  if (consentGranted === consentDenied) {
    store.dispatch('analytics/unsetConsent')
  } else if (consentGranted) {
    store.dispatch('analytics/grantConsent')
  } else {
    store.dispatch('analytics/denyConsent')
  }
}

router.afterEach((to, _from) => {
  const token = localStorage.getItem('token')

  if (token && !store.state.user.id) {
    AuthService.loginFromToken(token, store)
  }

  setTitle(to.meta.title)

  setAnalyticsConsent()
})

export default router
