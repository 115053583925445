import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from './user'
import pagination from './pagination'
import analytics from './analytics'

export const defaultStore = () => ({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    pagination,
    analytics,
  },
})

export default new Vuex.Store(defaultStore())
